/* It contains all the API functions which is releated to My approvals section */
import axiosInstance from '../../middleware/Interceptor'

// to get pending requests and request history details for given user
function myPendingRequest (timeZone) {
  axiosInstance.defaults.headers.get.timezone = timeZone
  return axiosInstance.get('/api/v1/myrequests/mypendingrequest')
}

// function to approve or reject requests
function ApproveOrRejectRequest (input) {
  return axiosInstance.post('/api/ApproveOrRejectRequest', input)
}

const MyApprovalsService = {
  myPendingRequest,
  ApproveOrRejectRequest
}

export default MyApprovalsService
