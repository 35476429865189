import React, {
  useRef
} from 'react'
import {
  LinearProgress,
  makeStyles,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  FormControl,
  MenuItem
} from '@material-ui/core'
import {
  TextValidator,
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete,
  Alert
} from '@material-ui/lab'
import ComboBox from '../inputs/autocomplete'
import requestAccess from '../../services/api/requestaccessService'
import CloneRepoService from '../../services/api/cloneRepoService'
import repoPermissionService from '../../services/api/repoPermissionService'

const useStyle = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

export default function RepoPermissionsComponent () {
  const classes = useStyle()
  const form = useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [allProjects, setAllProjects] = React.useState([])
  const [repos, setRepos] = React.useState([])
  const [allRoles, setAllRoles] = React.useState([])

  const [formData, setFormData] = React.useState({
    projectkey: '',
    name: '',
    group: '',
    permission: ''
  })

  const permissions = [
    {
      name: 'Read',
      value: 'REPO_READ'
    },
    {
      name: 'Write',
      value: 'REPO_WRITE'
    },
    {
      name: 'Admin',
      value: 'REPO_ADMIN'
    }
  ]

  /* function to get the projects list based on the search key */
  const getProjects = (value) => {
    setLoadingbar(true)
    requestAccess.getprojects('bitbucket', value).then((response) => {
      setAllProjects(response.data)
      setLoadingbar(false)
    })
  }

  const getRoles = (value) => {
    setLoadingbar(true)
    repoPermissionService.getRoles(value).then((response) => {
      setLoadingbar(false)
      setAllRoles(response.data.groups)
    })
  }

  const changeKey = (value) => {
    if (value) {
      setLoading(true)
      CloneRepoService
        .getRepos4Project(value.key)
        .then((response) => {
          setLoading(false)
          setRepos(Array.isArray(response.data) ? response.data : [])
          setFormData({
            ...formData,
            projectkey: value.key
          })
        })
    }
  }

  const changeRole = (value) => {
    if (value) {
      setFormData({
        ...formData,
        group: value.group_name
      })
    }
  }

  const handleChange = (value) => {
    setFormData({
      ...formData,
      [value.target.name]: value.target.value
    })
  }

  const handleReset = () => {
    setAllProjects([])
    setRepos([])
    setAllRoles([])
    setFormData({
      projectkey: '',
      name: '',
      group: '',
      permission: ''
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    repoPermissionService.applyPermissionForGroup(formData).then((response) => {
      setLoading(false)
      if (response.data.statusCode === 204) {
        setSuccessalertMessage(`
          Permission added or updated successfully to the selected Repository
        `)
        setFormData({
          ...formData,
          group: '',
          permission: ''
        })
        setsuccessAlert(true)
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      } else {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    }, (error) => {
      setLoading(false)
      if (error) {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={() => handleSubmit()}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Repository Permission
                </Typography>
                {' '}
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id='select-project'
                    options={allProjects}
                    value={formData.projectkey ? formData.projectkey : ''}
                    getOptionLabel={
                      (option) => (option.key ? option.key : option)
                    }
                    loading={allProjects.length === 0 && loadingbar}
                    className={classes.inputDisplay}
                    disabled={formData.chooseUser === ''}
                    getOptionSelected={
                      (option, value) => option.key === value.key
                    }
                    onChange={(event, value) => changeKey(value)}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label='Type a valid project key *'
                        name='projectkey'
                        onChange={(event) => getProjects(event.target.value)}
                        value={formData.projectkey}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    )}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label='Repository'
                    name='name'
                    required
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  >
                    {repos.map((value, index) => (
                      <MenuItem value={value.slug} key={value.name}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id='select-role'
                    options={allRoles}
                    value={formData.group ? formData.group : ''}
                    getOptionLabel={
                      (option) => (option.group_name
                        ? option.group_name
                        : option)
                    }
                    loading={allRoles.length === 0 && loadingbar}
                    className={classes.inputDisplay}
                    disabled={formData.name === ''}
                    getOptionSelected={
                      (option, value) => option.group_name === value.group_name
                    }
                    onChange={(event, value) => changeRole(value)}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label='Group *'
                        name='group'
                        onChange={(event) => getRoles(event.target.value)}
                        value={formData.group}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    )}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label='Permission'
                    name='permission'
                    required
                    fullWidth
                    value={formData.permission}
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  >
                    {permissions.map((value, index) => (
                      <MenuItem value={value.value} key={value.name}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={loading}
                >
                  Submit
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
