import React from 'react'
import {
  makeStyles,
  LinearProgress,
  Grid,
  Typography,
  Button,
  Popover,
  Card,
  CardContent,
  TextField,
  Chip,
  CardActions,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import {
  TreeView,
  TreeItem,
  Autocomplete
} from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import DehazeIcon from '@material-ui/icons/Dehaze'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import cookies from 'js-cookie'
import moment from 'moment-timezone'
import ComboBox from '../inputs/autocomplete'
import requestAccess from '../../services/api/requestaccessService'
import rtmReportService from '../../services/api/rtmRepotService'
import expandall from '../../assets/images/expandall.png'
import excelicon from '../../assets/images/excelicon.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  padding0: {
    padding: 0
  },
  btnmargin: {
    margin: 10
  },
  typography: {
    padding: theme.spacing(2)
  },
  cardroot: {
    minWidth: 745,
    maxWidth: 800,
    maxHeight: 500
  },
  bordercolor: {
    borderRight: '1px solid #F5F5F5'
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  issueHeight: {
    maxHeight: 250,
    overflowY: 'auto',
    flexWrap: 'initial'
  },
  footer: {
    margin: theme.spacing(1),
    float: 'right'
  },
  commentbox: {
    width: 300,
    paddingBottom: 20
  }
}))
const headingArray = [
  {
    name: 'Summary',
    flex: '20%'
  },
  {
    name: 'Version',
    flex: '10%'
  },
  {
    name: 'Status',
    flex: '15%'
  }
]
export default function RtmReportComponent () {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [filter, setFilter] = React.useState(false)
  const [custom, setCustom] = React.useState(false)
  const [allprojects, setAllProjects] = React.useState([])
  const [rtmdata, setRtmData] = React.useState([])
  const [commentValue, setCommentValue] = React.useState('')
  const [issuetypelinks, setIssueTypeLinks] = React.useState([{
    name: 'has epic',
    check: true
  },
  {
    name: 'is child task of',
    check: true
  },
  {
    name: 'tested by',
    check: true
  }])
  const [issuetypes, setIssueTypes] = React.useState([])
  const [issuetypescopy, setIssueTypesCopy] = React.useState(['theme',
    'epic',
    'feature',
    'story',
    'use case',
    'test'])
  const [completedissue, setCompletedIssue] = React.useState(null)
  const completedissues = [
    {
      id: '5555',
      issuetype: 'Story'
    },
    {
      id: '6666',
      issuetype: 'Feature'
    }
  ]
  const [customfieldArrayForRTM, setCustomfieldArrayForRTM] = React.useState([
    {
      cfname: 'Acceptance Criteria'
    },
    {
      cfname: 'Test Category'
    },
    {
      cfname: 'Business Process Document'
    },
    {
      cfname: 'Design Documents'
    },
    {
      cfname: 'Approval Category'
    },
    {
      cfname: 'External Document Reference'
    }
  ])
  const [customfields, setcustomfields] = React.useState([])
  const [versions, setVersions] = React.useState([])
  const [allversions, setAllVersions] = React.useState([])
  const [epics, setEpics] = React.useState([])
  const [allEpics, setAllEpics] = React.useState([])
  const [treedata, setTreeData] = React.useState([])
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [projectkey, setProjectKey] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElCustom, setanchorElCustom] = React.useState(null)
  const [alertopen, setAlertOpen] = React.useState(false)
  const [defaulexpanded, setDefaultExpanded] = React.useState([])
  const timeZone = moment.tz.guess()

  function getnodes (node) {
    const acTrue = customfields.filter((el) => el ===
      'Acceptance Criteria')

    const tcTrue = customfields.filter((el) => el === 'Test Category')
    /* console.log('acTrue', acTrue)
    console.log('tcTrue', tcTrue) */
    return (
      <Table>
        <TableRow>
          <TableCell component='th' scope='row'>
            {node.name}
          </TableCell>
          <TableCell
            width={
              `${(60 / (headingArray.length + customfields.length) + 10) +
                (node.depth === 3 ? 2 : 1.5)}%`
            }
          >
            {node.summary}
          </TableCell>
          <TableCell
            width={
              `${60 / (headingArray.length + customfields.length)}%`
            }
          >
            {node.version}
          </TableCell>
          <TableCell
            width={
              `${(60 / (headingArray.length + customfields.length)) +
                node.depth / 2}%`
            }
          >
            {node.issue_status}
          </TableCell>
          {acTrue.length
            ? (
              <TableCell
                width={
                  `${60 / (headingArray.length + customfields.length) + 10}%`
                }
              >
                {node.ac}
              </TableCell>
            )
            : null}
          {tcTrue.length
            ? (
              <TableCell
                width={
                  `${60 / (headingArray.length + customfields.length)}%`
                }
              >
                {node.testcategory}
              </TableCell>
            )
            : null}
          {customfields.map((item) => (

            node.custom &&
              Object.keys(node.custom).length > 0 &&
              item !== 'Acceptance Criteria' &&
              item !== 'Test Category' ? (
                <TableCell
                  key={node.custom[item]}
                  width={`${60 / (headingArray.length + customfields.length)}%`}
                  style={{
                    wordBreak: 'break-word'
                  }}
                >
                  {node.custom[item] && node.custom[item][0] !== null
                    ? node.custom[item].toString()
                    : 'NA'}
                </TableCell>
              ) : null

          ))}
        </TableRow>
      </Table>
    )
  }
  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={getnodes(nodes)}
      onClick={() => setExpand(nodes)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  )

  /* function to get the projects list based on the search key */
  const getProjects = (value) => {
    setLoadingbar(true)
    requestAccess.getprojects('jira', value).then((response) => {
      setAllProjects(response.data)
      setLoadingbar(false)
    })
  }

  /* To get rtm data for selected project */
  const changeProject = (value) => {
    if (value) {
      const data1 = cookies.get(value.key)
      const issuetypescopydata = data1 && data1.issuetypes
        ? data1.issuetypes
        : issuetypescopy
      setIssueTypeLinks(data1 && data1.issuelinks
        ? data1.issuelinks
        : issuetypelinks)
      setProjectKey(value.key)
      setLoading(true)
      rtmReportService.getIssueType(value.key).then((issueresponse) => {
        const issuetypedata = issueresponse.data
        issuetypedata.map((item, i) => {
          if (issuetypescopydata.includes(item.issuetype.toLowerCase())) {
            issuetypedata[i].check = true
          }
        })
        setRtmData([])
        setTreeData([])
        setIssueTypesCopy(issuetypescopydata)
        setIssueTypes(issuetypedata)
        setLoading(false)
        rtmReportService.getVersionForJiraProject(value.key, null)
          .then((versionresponse) => {
            setAllVersions(versionresponse.data)
            rtmReportService.getEpicForProject(value.key, null)
              .then((epciresponse) => {
                setAllEpics(epciresponse.data)
              })
          })
      })
    }
  }
  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }
  const checkdisabled = () => {
    const array = []
    customfieldArrayForRTM.map((item) => {
      if (
        item.check &&
        item.cfname !== 'Acceptance Criteria' &&
        item.cfname !== 'Test Category'
      ) {
        array.push(item)
      }
    })

    if (array.length === 4) {
      return true
    }
  }
  const openAlert = () => {
    setCommentValue('')
    setAlertOpen(true)
  }
  const changeIssueLinks = (event, index) => {
    issuetypelinks[index].check = event.target.checked
    setIssueTypeLinks([...issuetypelinks])
  }
  const getcustomfield = () => {
    setCustom(false)
    setcustomfields([])
    const customfieldsdata = []
    console.log('customfieldArrayForRTM', customfieldArrayForRTM)
    customfieldArrayForRTM.map((item, i) => {
      /* if (item.cfname === 'Acceptance Criteria' ||
      item.cfname === 'Test Category') {
        customfieldArrayForRTM[i].check = true
      } else if (item.check) {
        customfieldsdata.push(item.cfname)
      } */
      if (item.check) {
        customfieldsdata.push(item.cfname)
      }
    })
    setCustomfieldArrayForRTM(customfieldArrayForRTM)
    setcustomfields(customfieldsdata)
  }
  const changeIssueTypes = (event, index) => {
    issuetypes[index].check = event.target.checked
    setIssueTypes([...issuetypes])
  }
  const handleClickFilter = (event) => {
    setFilter(true)
    setAnchorEl(event.currentTarget)
  }
  const handleClickCustom = (event) => {
    setCustom(true)
    setanchorElCustom(event.currentTarget)
  }
  const changeVersion = (value) => {
    setVersions(value)
  }
  const changeEpic = (value) => {
    setEpics(value)
  }
  const changeCustomField = (event, index) => {
    const customfieldArrayForRTMdata = customfieldArrayForRTM
    customfieldArrayForRTMdata[index].check = event.target.checked
    setCustomfieldArrayForRTM([...customfieldArrayForRTMdata])
  }
  const clearFilter = () => {
    setFilter(false)
    setVersions([])
    setEpics([])
    setIssueTypeLinks([{
      name: 'has epic',
      check: true
    },
    {
      name: 'is child task of',
      check: true
    },
    {
      name: 'tested by',
      check: true
    }])
    setIssueTypesCopy(['theme',
      'epic',
      'feature',
      'story',
      'use case',
      'test'])
    setCompletedIssue(null)
    generateRTM('reset')
  }

  const setComment = (e) => {
    setCommentValue(e.target.value)
  }
  const applyFilter = () => {
    setFilter(false)
    setIssueTypesCopy([])
    issuetypes.map((item) => {
      if (item.check) {
        issuetypescopy.push(item.issuetype.toLowerCase())
      }
    })
    setIssueTypesCopy(issuetypescopy)
    cookies.set(projectkey, JSON.stringify({
      issuetypes: issuetypescopy,
      issuelinks: issuetypelinks
    }))
    generateRTM()
  }
  const ExpandAll = () => {
    const array = []
    rtmdata.map((item, i) => {
      array.push(i.toString())
    })
    setDefaultExpanded(array)
  }
  const CollapseAll = () => {
    setDefaultExpanded([])
  }

  const setExpand = (node) => {
    const array = defaulexpanded
    if (array.includes(node.id.toString())) {
      array.splice(array.indexOf(node.id.toString()), 1)
    } else {
      array.push(node.id.toString())
    }
    setDefaultExpanded([...array])
  }
  // API call to get versions for auto complete text box
  const getVersions = (value) => {
    rtmReportService.getVersionForJiraProject(projectkey,
      value).then((versionresponse) => {
      setAllVersions(versionresponse.data)
    })
  }
  // API call to get versions for auto complete text box
  const getEpics = (value) => {
    rtmReportService.getEpicForProject(projectkey,
      value).then((epicresponse) => {
      setAllEpics(epicresponse.data)
    })
  }
  const exportPDF = () => {
    setAlertOpen(false)
    const issuetypesdata = []
    issuetypelinks.map((item) => (item.check
      ? issuetypesdata.push(`'${item.name}'`)
      : null))
    const versiondata = []
    versions.map((item) => (versiondata.push(`
      '${item.version_name.replace(',', '|')}'
    `)))
    const epicdata = []
    epics.map((item) => (epicdata.push(`'${item.id}'`)))
    const input = {
      completedissue: completedissue || '',
      issuelinks: issuetypesdata.toString(),
      issuetypes,
      projectkey,
      version_filter: versions.length ? versiondata.toString() : 'all',
      epic_filter: epics.length ? epicdata.toString() : 'all',
      commentValue,
      custom: customfields
    }
    setLoading(true)
    rtmReportService.exportRTM(input, timeZone).then((response) => {
      setLoading(false)
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.className = 'hidden'
      link.download = `Report_${new Date()}.pdf`
      document.body.appendChild(link)
      link.click()
    })
  }
  const exportExcel = () => {
    setAlertOpen(false)
    const issuetypesdata = []
    issuetypelinks.map((item) => (item.check
      ? issuetypesdata.push(`'${item.name}'`)
      : null))
    const versiondata = []
    versions.map((item) => (versiondata.push(`
      '${item.version_name.replace(',', '|')}'
    `)))
    const epicdata = []
    epics.map((item) => (epicdata.push(`'${item.id}'`)))
    const input = {
      completedissue: completedissue || '',
      issuelinks: issuetypesdata.toString(),
      issuetypes,
      projectkey,
      version_filter: versions.length ? versiondata.toString() : 'all',
      epic_filter: epics.length ? epicdata.toString() : 'all',
      commentValue,
      custom: customfields
    }
    setLoading(true)
    rtmReportService.exportRTMExcel(input, timeZone).then((response) => {
      setLoading(false)
      const blob = new Blob([response.data], {
        type: `
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        `
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.className = 'hidden'
      link.download = `Report_${new Date()}.xlsx`
      document.body.appendChild(link)
      link.click()
    })
  }
  const generateRTM = (reset) => {
    let input = {
    }
    if (reset) {
      const issuelinksdata = []
      const issuetypesdata = issuetypes
      issuetypesdata.map((item, i) => {
        if (['theme',
          'epic',
          'feature',
          'story',
          'use case',
          'test'].includes(item.issuetype.toLowerCase())) {
          issuetypesdata[i].check = true
        } else {
          issuetypesdata[i].check = false
        }
      })
      const issuelinkscopydata = [{
        name: 'has epic',
        check: true
      },
      {
        name: 'is child task of',
        check: true
      },
      {
        name: 'tested by',
        check: true
      }]
      issuelinkscopydata.map((item) => (item.check
        ? issuelinksdata.push(`'${item.name}'`)
        : null))
      input = {
        completedissue: '',
        issuelinks: issuelinksdata.toString(),
        issuetypes: issuetypesdata,
        projectkey,
        version_filter: 'all',
        epic_filter: 'all'
      }
    } else {
      const issuetypesdata = []
      issuetypelinks.map((item) => (item.check
        ? issuetypesdata.push(`'${item.name}'`)
        : null))
      const versiondata = []
      versions.map((item) => (versiondata.push(`
        '${item.version_name.replace(',', '|')}'
      `)))
      const epicdata = []
      epics.map((item) => (epicdata.push(`'${item.id}'`)))
      input = {
        completedissue: completedissue || '',
        issuelinks: issuetypesdata.toString(),
        issuetypes,
        projectkey,
        version_filter: versions.length ? versiondata.toString() : 'all',
        epic_filter: epics.length ? epicdata.toString() : 'all'
      }
    }

    setLoading(true)
    rtmReportService.getRTMData(input).then((response) => {
      setLoading(false)
      setRtmData(response.data)
      const rtmdata1 = response.data
      const treedata1 = []
      if (rtmdata1.message) {
        // known empty block
      } else {
        let ajson1 = {
          name: '',
          children: [],
          breadarray: '',
          depth: '',
          summary: '',
          ac: '',
          version: '',
          issue_status: '',
          testcategory: '',
          custom: {
          }
        }
        // building tree structure data like parent child
        rtmdata1.map((a, ai) => {
          if (a.depth === 0) {
            if (ajson1.name !== '') {
              treedata1.push(ajson1)
            }
            ajson1 = {
              ...a,
              name: `${a.issue_num} (${a.issue_type})`,
              children: [],
              id: ai.toString()
            }
          } else {
            rtmdata1[ai].breadarray = a.breadarray.split(',')
            if (a.breadarray.includes(ajson1.breadarray)) {
              if (a.breadarray.length === 2) {
                ajson1.children.push({
                  ...a,
                  name: `${a.issue_num.split(' ').join('')} (${a.issue_type})`,
                  id: ai.toString()
                })
              } else if (a.breadarray.length === 3) {
                ajson1.children.map((b, bi) => {
                  if (!b.children) {
                    ajson1.children[bi].children = []
                  }
                  if (
                    a.breadarray.includes(b.breadarray[0]) &&
                    a.breadarray.includes(b.breadarray[1])
                  ) {
                    ajson1.children[bi].children.push({
                      ...a,
                      name: `
                        ${a.issue_num.split(' ').join('')} (${a.issue_type})
                      `,
                      id: ai.toString()
                    })
                  }
                })
              } else if (a.breadarray.length === 4) {
                ajson1.children.map((b, bi) => {
                  b.children.map((c, ci) => {
                    if (!c.children) {
                      ajson1.children[bi].children[ci].children = []
                    }
                    if (
                      a.breadarray.includes(c.breadarray[0]) &&
                      a.breadarray.includes(c.breadarray[1]) &&
                      a.breadarray.includes(c.breadarray[2])
                    ) {
                      ajson1.children[bi].children[ci].children.push({
                        ...a,
                        name: `
                          ${a.issue_num.split(' ').join('')} (${a.issue_type})
                        `,
                        id: ai.toString()
                      })
                    }
                  })
                })
              } else if (a.breadarray.length === 5) {
                ajson1.children.map((b, bi) => {
                  b.children.map((c, ci) => {
                    c.children.map((d, di) => {
                      if (!d.children) {
                        ajson1.children[bi].children[ci]
                          .children[di].children = []
                      }
                      if (
                        a.breadarray.includes(d.breadarray[0]) &&
                        a.breadarray.includes(d.breadarray[1]) &&
                        a.breadarray.includes(d.breadarray[2]) &&
                        a.breadarray.includes(d.breadarray[3])
                      ) {
                        ajson1.children[bi].children[ci].children[di].children
                          .push({
                            ...a,
                            name: `
                              ${a.issue_num.split(' ')
    .join('')} (${a.issue_type})
                            `,
                            id: ai.toString()
                          })
                      }
                    })
                  })
                })
              }
            }
          }
          if (ai === (rtmdata1.length - 1)) {
            treedata1.push(ajson1)
            setTreeData(treedata1)
            getcustomfield()
          }
        })
      }
    })
  }
  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <Typography variant='subtitle1' gutterBottom>
        Requirement Traceability Matrix
        {treedata.length
          ? (
            <>
              <IconButton
                aria-label='Expand All'
                title='Expand All'
                className='pull-right'
                onClick={() => ExpandAll()}
              >
                <img
                  src={expandall}
                  width='25'
                  height='25'
                  alt=''
                />
              </IconButton>
              <IconButton
                aria-label='Collapse All'
                title='Collapse All'
                className='pull-right'
                onClick={() => CollapseAll()}
              >
                <DehazeIcon />
              </IconButton>
              <IconButton
                aria-label='download'
                className='pull-right'
                onClick={() => openAlert()}
              >
                <PictureAsPdfIcon
                  color='secondary'
                  style={{
                    fontSize: 25
                  }}
                />
              </IconButton>
              <IconButton
                aria-label='download'
                className='pull-right'
                onClick={() => exportExcel()}
              >
                <img
                  src={excelicon}
                  width='25'
                  height='25'
                  alt=''
                />
              </IconButton>
            </>
          )
          : null}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6} md={3}>

          <ComboBox
            allProjects={allprojects}
            name='jira-project-key'
            label='Select Jira Project'
            changeOption={changeProject}
            getProjects={getProjects}
            loading={loadingbar}
          />

        </Grid>
        <Grid item xs={6} md={5}>
          {projectkey || treedata.length ? (
            <Button
              aria-describedby='filter-box'
              variant='outlined'
              onClick={handleClickFilter}
              className={classes.btnmargin}
              startIcon={<FilterListIcon />}
            >
              Filter
            </Button>
          ) : null}
          {treedata.length ? (
            <Button
              aria-describedby='custom-field-box'
              variant='outlined'
              onClick={handleClickCustom}
              className={classes.btnmargin}
              startIcon={<CheckCircleOutlineIcon />}
            >
              Custom Fields
            </Button>
          ) : null}
          {projectkey && !treedata.length ? (
            <Button
              aria-describedby='custom-field-box'
              variant='outlined'
              onClick={generateRTM}
              className={classes.btnmargin}
            >
              Generate RTM
            </Button>
          ) : null}
          <Popover
            id='filter-box'
            open={filter}
            anchorEl={anchorEl}
            onClose={() => setFilter(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Card
              className={classes.cardroot}
              style={{
                overflow: 'auto'
              }}
            >
              <CardContent>

                <Grid container spacing={1}>
                  <Grid item xs={3} md={3} className={classes.bordercolor}>
                    <FormControl
                      component='fieldset'
                      className={classes.formControl}
                    >
                      <FormLabel component='legend'>Issue links</FormLabel>
                      <FormGroup>
                        {issuetypelinks.map((item, index) => (
                          <FormControlLabel
                            key={item.name}
                            control={(
                              <Checkbox
                                name={item.name}
                                checked={item.check}
                              />
                            )}
                            label={item.name}
                            onChange={(e) => changeIssueLinks(e, index)}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} md={3} className={classes.bordercolor}>
                    <FormControl
                      component='fieldset'
                      className={classes.formControl}
                    >
                      <FormLabel component='legend'>Issue Types</FormLabel>
                      <FormGroup className={classes.issueHeight}>
                        {issuetypes.map((item, index) => (
                          <FormControlLabel
                            key={item.issuetype}
                            control={(
                              <Checkbox
                                name={item.issuetype}
                                checked={item.check}
                              />
                            )}
                            label={item.issuetype}
                            onChange={(e) => changeIssueTypes(e, index)}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} md={6} className={classes.bordercolor}>
                    <FormLabel
                      component='legend'
                      className={classes.formControl}
                    >
                      Version
                    </FormLabel>
                    <Autocomplete
                      id='combo-box-demo'
                      multiple
                      defaultValue={versions}
                      options={allversions}
                      getOptionLabel={(option) => (option.version_name
                        ? option.version_name
                        : option)}
                      renderTags={(value, getTagProps) => value.map((option,
                        index) => (versions.length ? (
                        <Chip
                          label={
                            option.version_name
                              ? option.version_name
                              : option
                          }
                          {...getTagProps({
                            index
                          })}
                        />
                      ) : (
                        setValuenull(value)
                      )))}
                      onChange={(event, value) => changeVersion(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name='versions'
                          value={versions}
                          onChange={(event) => getVersions(event.target.value)}
                        />
                      )}
                    />

                    {/* </Grid>
                  <Grid item xs={3} md={3}> */}
                    <FormControl className={classes.formControl}>
                      <FormLabel component='legend'>Completed Issue</FormLabel>
                      <Select
                        value={completedissue}
                        onChange={(e) => { setCompletedIssue(e.target.value) }}
                      >

                        <MenuItem>None</MenuItem>
                        {completedissues.map((item) => (
                          <MenuItem
                            key={item.issuetype}
                            value={item.issuetype}
                          >
                            {item.issuetype}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormLabel
                      component='legend'
                      className={classes.formControl}
                    >
                      Epic Link
                    </FormLabel>
                    <Autocomplete
                      id='combo-box-demo'
                      multiple
                      defaultValue={epics}
                      options={allEpics}
                      getOptionLabel={(option) => (option.issuenum
                        ? `${option.issuenum} - ${option.summary}`
                        : option)}
                      renderTags={(value, getTagProps) => value.map((option,
                        index) => (epics.length ? (
                        <Chip
                          label={
                            option.issuenum
                              ? `${option.issuenum} - ${option.summary}`
                              : option
                          }
                          {...getTagProps({
                            index
                          })}
                        />
                      ) : (
                        setValuenull(value)
                      )))}
                      onChange={(event, value) => changeEpic(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name='epics'
                          value={epics}
                          onChange={(event) => getEpics(event.target.value)}
                        />
                      )}
                    />

                  </Grid>
                </Grid>

              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  size='small'
                  variant='outlined'
                  onClick={() => clearFilter()}
                >
                  Reset Filter
                </Button>
                <Button
                  size='small'
                  color='primary'
                  variant='outlined'
                  onClick={() => applyFilter()}
                >
                  Apply filter
                </Button>
              </CardActions>
            </Card>
          </Popover>
          <Popover
            id='custom-field-box'
            open={custom}
            anchorEl={anchorElCustom}
            onClose={() => setCustom(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >

            <Card>
              <CardContent>

                <FormControl
                  component='fieldset'
                  className={classes.formControl}
                >
                  <FormGroup>
                    {customfieldArrayForRTM.map((item, index) => (
                      <FormControlLabel
                        key={item.cfname}
                        control={(
                          <Checkbox
                            name={item.cfname}
                            checked={item.check}
                          />
                        )}
                        label={item.cfname}
                        /* disabled={item.cfname === 'Acceptance Criteria' ||
                          item.cfname === 'Test Category' ||
                          (checkdisabled() && !item.check)} */
                        onChange={(e) => changeCustomField(e, index)}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  size='small'
                  variant='outlined'
                  onClick={() => setCustom(false)}
                >
                  Cancel
                </Button>
                <Button
                  size='small'
                  color='primary'
                  variant='outlined'
                  onClick={() => getcustomfield()}
                >
                  Apply
                </Button>
              </CardActions>
            </Card>
          </Popover>
        </Grid>
      </Grid>
      {!loading && !rtmdata.message && !rtmdata.length ? (
        <div
          className={classes.nodatatext}
          key='nodatatext2'
        >
          No Records Found
        </div>
      ) : null}
      {!loading && rtmdata.message ? (
        <div className={classes.nodatatext} key='nodatatext2'>
          {' '}
          {rtmdata.message}
        </div>
      ) : null}
      {!rtmdata.message && rtmdata.length
        ? (
          <div>
            <TableContainer component={Paper}>
              <Table stickyHeader size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Issue</TableCell>
                    {headingArray.map((head) => (
                      <TableCell
                        key={head.name}
                        width={
                          (head.name === 'Summary' ||
                          head.name === 'Acceptance Criteria')
                            ? `${60 / (headingArray.length +
                              customfields.length) + 10}%`
                            : `${60 / (headingArray.length +
                              customfields.length)}%`
                        }
                      >
                        {head.name}
                      </TableCell>
                    ))}
                    {customfields.map((head) => (
                      <TableCell
                        key={head.name}
                        width={
                          head === 'Acceptance Criteria'
                            ? `${60 / (headingArray.length +
                              customfields.length) + 10}%`
                            : `${60 / (headingArray.length +
                              customfields.length)}%`
                        }
                      >
                        {head}
                      </TableCell>
                    ))}

                  </TableRow>
                </TableHead>

              </Table>
            </TableContainer>

            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              expanded={defaulexpanded}
              defaultExpandIcon={<ChevronRightIcon />}
              style={{
                maxHeight: '65vh',
                overflow: 'auto'
              }}

            >

              {treedata.length
                ? treedata.map((tree) => renderTree(tree))
                : null}

            </TreeView>
          </div>
        )
        : null}
      <Dialog
        onClose={() => setAlertOpen(false)}
        aria-labelledby='simple-dialog-title'
        open={alertopen}
      >
        <DialogTitle id='alert-dialog-title'>
          Enter Comments
        </DialogTitle>
        <DialogContent>
          <div className={classes.commentbox}>
            <TextField
              style={{
                width: '100%'
              }}
              onChange={setComment}
              multiline
              label='Type your comments *'
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setAlertOpen(false)}>
            Cancel
          </Button>
          <Button
            color='primary'
            autoFocus
            onClick={() => exportPDF()}
            disabled={commentValue === ''}
          >
            Export to pdf
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  )
}
