/* eslint max-len: 0 */
import React, {
  useRef, useEffect
} from 'react'
import {
  LinearProgress,
  makeStyles,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  FormControl,
  List,
  ListItem,
  Checkbox,
  ListItemText
} from '@material-ui/core'
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import {
  Autocomplete,
  Alert
} from '@material-ui/lab'
import OwnershipServices from '../../services/api/ownershipServices'
import ProjectKeyService from '../../services/api/projectKeyService'
import ToolsService from '../../services/api/toolsService'
import globalData from '../../data/globalData.json'

const Filter = require('bad-words')

const useStyle = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

export default function ProjectKeyComponent () {
  const classes = useStyle()
  const form = useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [loadingOptions, setLoadingOptions] = React.useState(false)
  const [allUsers, setAllUsers] = React.useState([])
  const [rolesForTool, setRolesForTool] = React.useState([])

  const [formData, setFormData] = React.useState({
    projectKey: '',
    owner: '',
    ownerDisplay: '',
    maxKey: '',
    selectedTool: [],
    approverGroup: '',
    idmsGroups: []
  })

  // Available tools to create keys
  const tools1 = [
    {
      name: 'Account',
      is_key: true
    },
    {
      name: 'Jira',
      is_key: true
    },
    {
      name: 'Confluence',
      is_key: true
    },
    {
      name: 'Bitbucket',
      is_key: true
    },
    {
      name: 'EAT Jenkins and Artifactory',
      is_key: true
    },
    {
      name: 'qTest',
      is_key: true
    }
  ]

  const filter = new Filter()
  const [swearWordsFromDB, setSwearWordsFromDB] = React.useState([])
  const [tools, setTools] = React.useState([])

  React.useEffect(() => {
    initMethod()
  }, [])

  const initMethod = () => {
    ToolsService.getToolsForForCreateGroups().then((result) => {
      setTools(result.data)
    })
  }
  // to attach validation to the field
  useEffect(() => {
    ValidatorForm.addValidationRule('isKeyExist', async (value) => {
      if (value) {
        const result = await getDataPromise(value)
        return result
      }
      return true
    })
    const getDataPromise = (value) => new Promise((resolve, reject) => {
      ProjectKeyService.isKeyExist(value).then((response) => {
        if (response.data === true) {
          resolve(false)
        }
        resolve(true)
      })
    })
  })

  // UseEffect to run only once
  useEffect(() => {
    ProjectKeyService.getOffensiveWords().then((response) => {
      setSwearWordsFromDB(response.data.offensivewords.split(','))
    })
  }, [])

  // Handling Change in field values
  const handleChange = (event) => {
    filter.addWords(...swearWordsFromDB)
    const cleanedWord = filter.clean(formData.projectKey)
    let input = {
    }
    if (!cleanedWord.includes('*')) {
      setFailalertMessage(null)
      seterrorAlert(false)
      if (event.target.checked === true) {
        formData.selectedTool.push(event.target.value)
      } else if (event.target.checked === false) {
        const index = formData.selectedTool.indexOf(event.target.value)
        if (index > -1) {
          formData.selectedTool.splice(index, 1)
          for (const i in tools) {
            if (!event.target.checked && tools[i].name.toLowerCase() ===
            event.target.value.toLowerCase()) {
              tools[i].groups = []
            }
          }
        }
      }
      if (formData.selectedTool.length > 0) {
        input = {
          projectKey: formData.projectKey.toUpperCase(),
          tool: formData.selectedTool
        }
        setLoading(true)
        ProjectKeyService.getRolesForTool(input).then((response) => {
          setLoading(false)
          const incomingData = response.data
          /* eslint guard-for-in: 0 */
          for (const i in tools) {
            for (const j in incomingData) {
              if (tools[i].name.toLowerCase() ===
              incomingData[j].name.toLowerCase()) {
                tools[i].groups = incomingData[j].groups
                for (const k in tools[i].groups) {
                  tools[i].groups[k] = tools[i].groups[k]
                    .replace('-asx-', '-ASx-')
                }
              }
            }
          }
          setRolesForTool([...tools])
        })
      } else {
        setFormData({
          ...formData,
          projectKey: '',
          owner: '',
          ownerDisplay: '',
          maxKey: '',
          selectedTool: [],
          approverGroup: ''
        })
        setRolesForTool([])
      }
    } else {
      setFormData({
        ...formData,
        projectKey: ''
      })
      setFailalertMessage(`
        Please do not use curse or offensive words as project key
      `)
      seterrorAlert(true)
    }
  }

  const handleProjectKey = (event) => {
    const userInput = event.target.value
    let endKey
    setFormData({
      ...formData,
      maxKey: '',
      projectKey: userInput
    })
    if (userInput.length === 1) {
      endKey = userInput.substring(0, userInput.length - 1) +
      String.fromCharCode(userInput.charCodeAt(userInput.length - 1) + 1) // to get the next letter of alphabet
      setLoading(true)
      ProjectKeyService.getMaxKey(userInput, endKey).then((response) => {
        setLoading(false)
        if (!response.data[0].max_key || response.data[0].max_key == null) {
          setFormData({
            ...formData,
            maxKey: 'No key to display',
            projectKey: userInput
          })
        } else {
          setFormData({
            ...formData,
            maxKey: response.data[0].max_key,
            projectKey: userInput
          })
        }
      })
    } else if (userInput.length === 2) {
      endKey =
        userInput[1].substring(0, userInput[1].length - 1) +
        String.fromCharCode(userInput[1]
          .charCodeAt(userInput[1].length - 1) + 1) // to get the next letter of alphabet
      setLoading(true)
      ProjectKeyService.getMaxKey(userInput, userInput[0] + endKey)
        .then((response) => {
          setLoading(false)
          setFormData({
            ...formData,
            maxKey: response.data[0].max_key,
            projectKey: userInput
          })
        })
    } else if (userInput.length > 2) {
      setFormData({
        ...formData,
        projectKey: userInput
      })
    }
  }

  // Getting users for owner
  const getUsers = (value) => {
    if (value) {
      setLoadingOptions(true)
      OwnershipServices.getallusersforautocomplete(value).then((response) => {
        setAllUsers(response.data)
        setLoadingOptions(false)
      })
    }
  }

  // Handling if there is any change in user value
  const changeUser = (value) => {
    if (value) {
      setFormData({
        ...formData,
        owner: value.name,
        ownerDisplay: `${value.name} - ${value.displayname}`
      })
      setAllUsers([])
    }
  }

  // Handling reset of the form
  const handleReset = () => {
    setFormData({
      ...formData,
      projectKey: '',
      owner: '',
      ownerDisplay: '',
      maxKey: '',
      selectedTool: [],
      idmsGroups: []
    })
    setFailalertMessage(null)
    seterrorAlert(false)
    setRolesForTool([])
    initMethod()
  }

  // const handleSubmits = (event) => {
  //   if(!swearWords.includes(formData.projectKey)) {
  //     //confirmSubmit()
  //   } else {
  //     setFormData({
  //       ...formData,
  //       projectKey: '',
  //       owner: '',
  //       ownerDisplay: '',
  //       maxKey: '',
  //       selectedTool: [],
  //       idmsGroups: []
  //     })
  //     setRolesForTool([])
  //     setFailalertMessage('Please do not use curse or offensive words as project key')
  //     seterrorAlert(true)
  //     event.preventDefault();
  //   }
  // }

  const handleSubmit = () => {
    setLoading(true)
    setLoading(false)
    for (const i in rolesForTool) {
      if (rolesForTool[i].groups && rolesForTool[i].groups.length > 0) {
        for (const j in rolesForTool[i].groups) {
          if (rolesForTool[i].groups.length > 0) {
            if (rolesForTool[i].name === 'Account') {
              if (rolesForTool[i].groups.length === 4) {
                formData.approverGroup = `ITS-ASx-${formData.projectKey.toUpperCase()}-Account-Owner`
              } else if (rolesForTool[i].groups[0].indexOf('SCM') > -1) {
                formData.approverGroup = `ITS-ASx-SCM-${formData.projectKey.toUpperCase()}-Account-Owner`
              } else {
                formData.approverGroup = `ITS-ASx-${formData.projectKey.toUpperCase()}-Account-Owner`
              }
            } else if (rolesForTool[i].groups[0].indexOf('SCM') > -1) {
              formData.approverGroup = `ITS-ASx-SCM-${formData.projectKey.toUpperCase()}-Account-Approvers`
            } else {
              formData.approverGroup = `ITS-ASx-${formData.projectKey.toUpperCase()}-Account-Approvers`
            }
          }
          formData.idmsGroups.push({
            group_name: rolesForTool[i].groups[j],
            owner: formData.owner.toLowerCase(),
            manager_approval: 'false',
            approvers: formData.approverGroup
          })
        }
      }
    }
    setLoading(true)
    ProjectKeyService.createIDMSGroups(formData.idmsGroups,
      formData.projectKey).then((creationResponse) => {
      setLoading(false)
      if (creationResponse.status === 200 &&
        creationResponse.data.statusText === 'success') {
        setSuccessalertMessage(`
          New Project Key Created with Groups Successfully
        `)
        handleReset()
        setsuccessAlert(true)
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      } else {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    }, (error) => {
      setLoading(false)
      if (error) {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={() => handleSubmit()}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Create Project Key
                </Typography>
                {' '}
                <FormControl className={classes.formControl}>
                  <TextValidator
                    label='Type Valid Project Key'
                    name='projectKey'
                    fullWidth
                    onChange={handleProjectKey}
                    value={formData.projectKey}
                    validators={['required', 'isKeyExist']}
                    errorMessages={
                      ['This field is required',
                        'The project key is already Exists.']
                    }
                  />
                  <br />
                  {formData.maxKey ? (
                    <span>
                      Max Key:
                      <b>{formData.maxKey}</b>
                    </span>
                  ) : null}
                </FormControl>

                <FormControl className={classes.formControl}>
                  <List component='nav' aria-label='main mailbox folders'>
                    {tools.map((tool) => (
                      tool.name !== 'Account' ? (
                        <ListItem key={tool.name}>
                          <Checkbox
                            name='selectedTool'
                            disabled={!formData.projectKey}
                            value={tool.name}
                            label={tool.name}
                            onChange={handleChange}
                            checked={
                              formData.selectedTool.indexOf(tool.name) > -1
                            }
                          />
                          <ListItemText>{tool.name}</ListItemText>
                        </ListItem>
                      ) : null
                    ))}
                  </List>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id='combo-box-user'
                    options={allUsers}
                    value={formData.ownerDisplay}
                    filterOptions={(options, state) => options}
                    getOptionLabel={
                      (option) => (option.name
                        ? `${option.name} - ${option.displayname}`
                        : option)
                    }
                    getOptionSelected={
                      (option, value) => option.name === value.name
                    }
                    onChange={(event, value) => changeUser(value)}
                    loading={allUsers.length === 0 && loadingOptions}
                    renderInput={(params) => (
                      <TextValidator
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label='Enter Owner Name'
                        value={formData.owner}
                        name='new-project-owner'
                        onChange={(event) => getUsers(event.target.value)}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    )}
                  />
                </FormControl>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={loading}
                // onClick={getAlertMessageON}
                >
                  Save
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {rolesForTool.length > 0 ? (
          <Grid item xs={12} md={5}>
            <Card variant='outlined'>
              <CardContent>
                {' '}
                <Typography variant='subtitle1' gutterBottom>
                  Groups
                </Typography>
                <List component='nav'>
                  {rolesForTool.map((roles) => (
                    roles.groups ? (
                      roles.groups.map((item) => (
                        <ListItem key={item}>
                          <ListItemText>{item}</ListItemText>
                        </ListItem>
                      ))
                    ) : null
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}
