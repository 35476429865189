import React, {
  useContext
} from 'react'
import {
  Paper,
  Typography,
  makeStyles,
  Tabs,
  Tab,
  Box,
  LinearProgress
} from '@material-ui/core'
import PropTypes from 'prop-types'
import UserContext from '../contexts/UserContext'
import EnhancedTable from '../sections/enhancedTable'
import MyRequestService from '../../services/api/myrequestService'
import LoginService from '../../services/api/loginService'
import globalData from '../../data/globalData.json'
import AlertDialog from '../alertbox/alertboxComponent'

// table header for pending request table
const headCells1 = [
  {
    id: 'originalkey',
    numeric: false,
    label: 'Approvers',
    page: 'My Pending Request'
  },
  {
    id: 'id',
    numeric: true,
    label: 'ID'
  },
  {
    id: 'requested_by',
    numeric: false,
    label: 'Requested By',
    icon: 'organization'
  },
  {
    id: 'requested_for',
    numeric: false,
    label: 'Requested For',
    icon: 'organization'
  },
  {
    id: 'project_key',
    numeric: false,
    label: 'Project'
  },
  {
    id: 'ad_group',
    numeric: false,
    label: 'Role',
    icon: 'approvers'
  },
  {
    id: 'created_sort',
    type: 'date',
    numeric: false,
    label: 'Requested Date'
  },
  {
    id: 'action_type',
    numeric: false,
    type: 'global',
    label: 'Action Type'
  },
  {
    id: 'delete',
    numeric: false,
    type: 'button',
    label: 'Delete'
  }
]
// table header for request history table
const headCells2 = [
  {
    id: 'requested_by',
    numeric: false,
    label: 'Requested By',
    icon: 'organization'
  },
  {
    id: 'requested_for',
    numeric: false,
    label: 'Requested For',
    icon: 'organization'
  },
  {
    id: 'project_key',
    numeric: false,
    label: 'Project'
  },
  {
    id: 'ad_group',
    numeric: false,
    label: 'Role'
  },
  {
    id: 'action_type',
    numeric: false,
    type: 'global',
    label: 'Action Type'
  },
  {
    id: 'created_sort',
    type: 'date',
    numeric: false,
    label: 'Requested Date'
  },
  {
    id: 'action_date_sort',
    type: 'date',
    numeric: false,
    label: 'Action Date'
  },
  {
    id: 'action_owner',
    numeric: false,
    label: 'Action Owner',
    icon: 'organization'
  },
  {
    id: 'action_status',
    numeric: false,
    type: 'global',
    label: 'Status'
  },
  {
    id: 'approval_comment',
    numeric: false,
    label: 'Approval Comments'
  },
  {
    id: 'agent_status',
    numeric: false,
    type: 'global',
    label: 'Provisioning Status'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  autocomplete: {
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  }
}))

function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}
export default function MyRequestsComponent () {
  const {
    user, setUser
  } = useContext(UserContext)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [pendingRequests, setPendingRequests] = React.useState([])
  const [requestHistory, setRequestHistory] = React.useState([])
  const [alertbox, setAlerbox] = React.useState(false)
  const [currentID, setCurrentID] = React.useState(null)

  /* initial method will be called when the page gets loaded */
  React.useEffect(() => {
    initMethod()
  }, [])
  /* change function for tab change */
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  /* function to get the all request details to serve for pendinf request and history tables */
  function initMethod () {
    setLoading(true)
    MyRequestService.myAccessRequest().then((response) => {
      setLoading(false)
      let pendingRequestsdata = []
      pendingRequestsdata = response.data
        .filter((data) => data.action_status === globalData.ACTION_PENDING)
      pendingRequestsdata.map((data, i) => (pendingRequestsdata[i]
        .project_key = `(${data.name}) ${data.project_key}`))
      let requestHistorydata = []
      requestHistorydata = response.data
        .filter((data) => data.action_status !== globalData.ACTION_PENDING)
      requestHistorydata.map((data, i) => (requestHistorydata[i]
        .project_key = `(${data.name}) ${data.project_key}`))
      setPendingRequests(pendingRequestsdata)
      setRequestHistory(requestHistorydata)
    })
  }

  /* to delete one item from requests */
  const deleteRequest = () => {
    setAlerbox(false)
    setLoading(true)
    MyRequestService.deleteMyRequest(currentID).then((response) => {
      setPendingRequests(pendingRequests.splice(currentID, 1))
      setLoading(false)
      initMethod()
      LoginService.getAdminaccess().then((approvaldata) => {
        setUser({
          ...user,
          approvalcount: approvaldata.data.approvalcount
        })
      })
    })
  }
  /* to close the alert box */
  const handleClose = () => {
    setAlerbox(false)
  }
  /* to open the alert box */
  const handleOpen = (id) => {
    setCurrentID(id)
    setAlerbox(true)
  }
  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
        >
          <Tab
            key='pendingrequests'
            label='Pending Requests'
            title='Pending Requests'
          />
          <Tab
            key='requesthistory'
            label='Request History'
            title='Request History'
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} key='tab-myrequest'>
        {pendingRequests.length ? (
          <EnhancedTable
            key='request-table'
            orderby='created_sort'
            sortorder='desc'
            headCells={headCells1}
            rowsData={pendingRequests}
            deleteItem={handleOpen}
          />
        ) : null}
        {!loading && !pendingRequests.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>

      <TabPanel value={value} index={1} key='tab-history'>
        {requestHistory.length ? (
          <EnhancedTable
            key='history-table'
            orderby='action_date_sort'
            sortorder='desc'
            headCells={headCells2}
            rowsData={requestHistory}
          />
        ) : null}
        {!loading && !requestHistory.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>
      <AlertDialog
        handleClose={handleClose}
        alertopen={alertbox}
        message='This request will be cancelled and it will not go to
          approvals anymore. Do you wish to continue?'
        confirmbutton={deleteRequest}
        key='alert1'
        title='Alert'
        okbuttonalert={false}
      />
    </div>
  )
}
