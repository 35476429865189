
/* It contains all the API functions which is releated to Tool access section */

import axiosInstance from '../../middleware/Interceptor'

function mytools (username) {
  return axiosInstance.get(`/api/v1/mytools/mytools?username=${username}`)
}
function getAvailableTools (value) {
  return axiosInstance.get(`/api/v1/mytools/getAvailableTools?
provisioning=${value}`)
}
function getToolsForForCreateGroups () {
  return axiosInstance.get('/api/v1/mytools/toosForCreateGroups')
}
// To get accessed roles for the given project and tool for the particular User
function getGroups (toolname, key, username, prefix) {
  return axiosInstance.get(`/api/v1/mytools/GetRolesForProjects?
toolName=${toolname}&projectKey=${key}&username=${username}&prefix=${prefix}`)
}
function getApiToolGroups (toolname) {
  return axiosInstance.get(`/api/v1/mytools/getApiToolGroups?
toolname=${toolname}`)
}

function getEnabledTools () {
  return axiosInstance.get('/api/v1/mytools/globalConfigTools')
}
function getEnabledAppLinks () {
  return axiosInstance.get('/api/v1/menu/applinks')
}

const ToolsService = {
  mytools,
  getToolsForForCreateGroups,
  getAvailableTools,
  getGroups,
  getApiToolGroups,
  getEnabledTools,
  getEnabledAppLinks
}

export default ToolsService
