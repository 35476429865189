import React, {
  useContext
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  lighten,
  makeStyles,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  InputAdornment,
  TextField,
  Toolbar,
  Checkbox,
  IconButton,
  Tooltip,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Button,
  Fade,
  Grid,
  Typography,
  Zoom,
  LinearProgress
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import WarningIcon from '@material-ui/icons/Warning'
import parse from 'html-react-parser'
import {
  is
} from 'date-fns/locale'
import ToolsService from '../../services/api/toolsService'
import AlertDialog from '../alertbox/alertboxComponent'
import globalData from '../../data/globalData.json'
import MyRequestService from '../../services/api/myrequestService'
import RequestAccessServices from '../../services/api/requestaccessService'
import SupportServices from '../../services/api/supportService'
import organization from '../../assets/images/organization.png'
import ESignComponent from '../alertbox/eSignComponent'
import UserContext from '../contexts/UserContext'
import ApigeeService from '../../services/api/apigeeService'

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

function EnhancedTableHead ({
  checkbox,
  headCells,
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  showButton
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {checkbox ? (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </TableCell>
        ) : null}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding='default'
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: `${headCell.width}%`,
              padding: 10
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  checkbox: PropTypes.bool.isRequired,
  headCells: PropTypes.array.isRequired,
  showButton: PropTypes.string
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 60%'
  },
  buttonMargin: {
    marginRight: 20,
    float: 'right'
  }
}))

const EnhancedTableToolbar = ({
  numSelected,
  handleSearch,
  selected,
  selectedRequest,
  approveOrReject,
  showButton,
  handleChangeApproverClick,
  handleChangeOwner
}) => {
  const classes = useToolbarStyles()
  const {
    user
  } = useContext(UserContext)
  const handleApproveOrReject = (type) => {
    approveOrReject(type, selected)
  }

  const handleChangeApprover = (type) => {
    handleChangeApproverClick(type, selected)
  }

  function getActionButtons (key) {
    switch (key) {
    case 'Change Approver':
      return (
        <>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.buttonMargin}
            onClick={() => handleChangeApprover('change')}
            disabled={user.switch_user}
          >
            Change
          </Button>
          <Button
            variant='contained'
            className={classes.buttonMargin}
            onClick={() => handleChangeApprover('reset')}
            disabled={user.switch_user}
          >
            Reset
          </Button>
        </>
      )
    case 'Change Owner':
      return (
        <Button
          variant='contained'
          color='primary'
          type='submit'
          className={classes.buttonMargin}
          onClick={() => handleChangeOwner(selectedRequest)}
          disabled={user.switch_user}
        >
          Change Owner
        </Button>
      )
    default:
      return (
        <>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.buttonMargin}
            disabled={user.switch_user}
            onClick={() => handleApproveOrReject('Reject')}
          >
            Reject
          </Button>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            size='small'
            className={classes.buttonMargin}
            disabled={user.switch_user}
            onClick={() => handleApproveOrReject('Approve')}
          >
            Approve
          </Button>
        </>
      )
    }
  }
  return (
    showButton !== 'dashboard' ? (
      <Toolbar
        className={clsx(classes.root)}
      >
        {' '}
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <TextField
              id='input-with-icon-textfield'
              label='Search'
              className={classes.margin}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={6} md={6}>

            {numSelected > 0 && getActionButtons(showButton)}
          </Grid>
        </Grid>
      </Toolbar>
    ) : null
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  approveOrReject: PropTypes.func,
  showButton: PropTypes.string,
  handleChangeApproverClick: PropTypes.func,
  handleChangeOwner: PropTypes.func,
  selectedRequest: PropTypes.array
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    width: '15%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  padding0: {
    paddingTop: 0,
    paddingBottom: 0
  },
  padding10: {
    padding: 10
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  disabled: {
    backgroundColor: '#e9ecef'
  },
  na: {
    backgroundColor: '#D3D3D3'
  },
  pullRight: {
    float: 'right'
  },
  margin: {
    margin: '10px'
  },
  aliveIcon: {
    color: '#32a852'
  },
  warningIcon: {
    color: '#d85411'
  }
}))

export default function EnhancedTable ({
  deleteItem,
  rowsData,
  headCells,
  checkbox,
  applink,
  currentUser,
  orderby,
  handleCompleteRequest,
  sortorder,
  showButton,
  handleChangeApproverClick,
  handleChangeOwner,
  rowsperpage,
  setMoreButton,
  numSelected,
  isApproval,
  jenkinsDetailsButton
}) {
  const classes = useStyles()
  const [order, setOrder] = React.useState(sortorder || 'asc')
  const [selected, setSelected] = React.useState([])
  const [selectedRequest, setSelectedRequest] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] =
    React.useState(rowsperpage || isApproval ? 50 : 10)
  const [title, setTitle] = React.useState(null)
  const [orderBy, setOrderBy] = React.useState(orderby)
  const [rows, setrows] = React.useState(rowsData)
  const [alertopen, setAlertopen] = React.useState(false)
  const [eSignbox, setESignbox] = React.useState(false)
  const [alertboxMessage, setAlertboxMessage] = React.useState('')
  const [usertooltip, setUsertooltip] = React.useState(null)
  const [buttonType, setButtonType] = React.useState(null)
  const [loadingExpand, setLoadingExpand] = React.useState(false)
  const {
    user
  } = useContext(UserContext)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      const newSelectedRequests = rows.map((n) => n)
      setSelectedRequest(newSelectedRequests)
      return
    }
    setSelected([])
    setSelectedRequest([])
  }
  // function to close the alert modal box
  const alertClose = (event) => {
    setAlertopen(false)
    setESignbox(false)
  }
  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    const newSelectedRequests = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
    rows.map((value, i) => {
      if (newSelected.includes(value.id)) {
        newSelectedRequests.push(value)
      }
    })
    setSelectedRequest(newSelectedRequests)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Build IRIS expansion component of ticket details
  const getExpandIrisData = (ticketDetails, body) => (
    parse(`
      <div>
        <h3>Status of the ticket : </h3>
        <span>${body.result[0].u_state}</span>
        <br/>
        ${body.result[0].u_state === 'Resolved'
      ? (`<h3>Resolution Notes : </h3>
            <span>${body.result[0].close_notes}</span>
            <br/>`)
      : '<br/>'
    }
        <h3>Ticket Details </h3>
        <div>${ticketDetails.toString()}</div>
        <br />
        <h3>Comments on the Ticket</h3>
        <span>${body.result[0].comments.length > 0
      ? `${body.result[0].comments}`
      : 'No comments found'}
        </span>
        <br/>
  </div>
  `)
  )

  // function to expand rows and get values for expanded row
  const expandRow = async (index, page1, id, ticketnumber) => {
    let indexvalue
    rows.map((item, i) => {
      if ((id && (item.key === id || item.id === id)) ||
        (ticketnumber && item.ticket_number === ticketnumber)) {
        indexvalue = i
      }
    })
    rows[indexvalue].show = !rows[indexvalue].show
    switch (page1) {
    case 'Project Access':
      if (!rows[indexvalue].expandedhtml) {
        setLoadingExpand(true)
        let resapprovers = []
        if (rows[indexvalue].toolname === 'Apigee') {
          resapprovers = await ApigeeService
            .getUsersForRole(rows[indexvalue].org, rows[indexvalue].group_name)
        } else {
          resapprovers = await RequestAccessServices
            .getuserbasedonrole(rows[indexvalue].group_name)
        }
        // RequestAccessServices.getuserbasedonrole(rows[indexvalue].group_name)
        // .then((response) => {
        if (resapprovers.data) {
          setLoadingExpand(false)
          const approvers = resapprovers.data
          rows[indexvalue].expandedhtml = (
            <List component='nav'>
              {approvers.length
                ? approvers.map((group) => (
                  <ListItem
                    key={group.username || group}
                  >
                    <ListItemText
                      primary={group.displayname || group}
                    />
                  </ListItem>
                )) : 'No data to display'}
            </List>
          )
          setrows([...rows])
        }
        // })
      } else {
        setrows([...rows])
      }
      break
    case 'View Submitted Request':
      if (!rows[indexvalue].expandedhtml) {
        if (rows[indexvalue].ticket_number.toString().substring(0, 3) ===
        'INC') {
          setLoadingExpand(true)
          SupportServices.getIRISTicketDetails('incident',
            `number=${rows[indexvalue].ticket_number}`,
            'work_notes, u_state, comments, close_notes, sys_id', true)
            .then((response) => {
              const tempData = getExpandIrisData(rows[indexvalue].ticket_detail
                .toString().split('|')[0], response.data.body)
              setLoadingExpand(false)
              rows[indexvalue].expandedhtml = tempData
              setrows([...rows])
            })
        } else {
          setLoadingExpand(true)
          SupportServices.getIRISTicketDetails('sc_req_item',
            `number=${rows[indexvalue].ticket_number}`, 'number, sys_id', true)
            .then((response) => {
              const RITMDetails = response.data.body.result[0]
              SupportServices.getIRISTicketDetails('sc_task',
                `request_item=${RITMDetails.sys_id}`,
                'work_notes, u_state, comments, close_notes', true)
                .then((response1) => {
                  setLoadingExpand(false)
                  const tempData = getExpandIrisData(rows[indexvalue]
                    .ticket_detail.toString().split('|')[0],
                  response1.data.body)
                  rows[indexvalue].expandedhtml = tempData
                  setrows([...rows])
                })
            })
        }
      } else {
        setrows([...rows])
      }
      break
    case 'My Pending Request':
      if (!rows[indexvalue].expandedhtml) {
        setLoadingExpand(true)
        if (rows[indexvalue].name === 'Apigee') {
          const org = rows[indexvalue].ad_group.split('_')[1]
          const team = rows[indexvalue].ad_group.split('_')[2]
          ApigeeService.getTeamApprovers(org, team)
            .then((response) => {
              setLoadingExpand(false)
              if (response.data) {
                const approvers = response.data
                rows[indexvalue].expandedhtml = (
                  <List component='nav'>
                    {approvers.length
                      ? approvers.map((group) => (
                        <ListItem
                          key={group}
                        >
                          <ListItemText
                            primary={group}
                          />
                        </ListItem>
                      )) : 'No data to display'}
                  </List>
                )
                setrows([...rows])
              }
            })
        } else {
          MyRequestService.getAccountApproverDetails(rows[indexvalue].ad_group)
            .then((response) => {
              setLoadingExpand(false)
              if (response.data) {
                const approvers = response.data
                rows[indexvalue].expandedhtml = (
                  <List component='nav'>
                    {approvers.length
                      ? approvers.map((group) => (
                        <ListItem
                          key={group.username}
                        >
                          <ListItemText
                            primary={group.displayname}
                          />
                        </ListItem>
                      )) : 'No data to display'}
                  </List>
                )
                setrows([...rows])
              }
            })
        }
      } else {
        setrows([...rows])
      }
      break
    case 'Tool Access':
      if (!rows[indexvalue].expandedhtml) {
        setLoadingExpand(true)
        ToolsService.getGroups(rows[indexvalue].toolname,
          rows[indexvalue].originalkey,
          currentUser,
          rows[indexvalue].prefix).then((response) => {
          setLoadingExpand(false)
          if (response.data) {
            const groups = response.data
            rows[indexvalue].expandedhtml = (
              <List component='nav'>
                {groups.length
                  ? groups.map((group) => (
                    <ListItem
                      key={group.group_name}
                    >
                      <ListItemText
                        primary={group.group_name}
                      />
                    </ListItem>
                  )) : 'No data to display'}
              </List>
            )
            setrows([...rows])
          }
        })
      } else {
        setrows([...rows])
      }
      break
    default: return null
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }
  // get href details for tools with keys
  const getHref = (url, key) => (key
    ? url.replace('<key>', key.toUpperCase())
    : null)
  // get approver details for requested role tooltip
  const getApprovers = (value, index, head, id) => {
    let indexvalue
    rows.map((item, i) => {
      if ((id && (item.key === id || item.id === id))) {
        indexvalue = i
      }
    })
    if (!rows[indexvalue].approverandowners) {
      setTitle(<div>Loading...</div>)
      if (rows[indexvalue].name === 'Apigee') {
        const org = rows[indexvalue].ad_group.split('_')[1]
        const team = rows[indexvalue].ad_group.split('_')[2]
        ApigeeService.getTeamApprovers(org, team)
          .then((response) => {
            const approvers = response.data
            rows[indexvalue].approverandowners = approvers
            setrows(rows)
            /* eslint react/jsx-closing-bracket-location: 0 */
            /* eslint react/jsx-closing-tag-location: 0 */
            setTitle(<div style={{
              padding: 10,
              textAlign: 'center'
            }}
            >
              <b>APPROVERS</b>
              {rows[indexvalue].approverandowners.map((user, i) => (
                <div key={user}>{user}</div>
              ))}
            </div>)
          })
      } else {
        MyRequestService.getOwnerAndApproversForGroup(value)
          .then((response) => {
            const [approvers] = response.data
            rows[indexvalue].approverandowners = approvers
            setrows(rows)
            /* eslint react/jsx-closing-bracket-location: 0 */
            /* eslint react/jsx-closing-tag-location: 0 */
            setTitle(<div style={{
              padding: 10,
              textAlign: 'center'
            }}
            >
              <b>OWNER</b>
              <div style={{
                marginBottom: 10
              }}
              >
                {rows[indexvalue].approverandowners.owner.displayname}
              </div>
              <b>APPROVERS</b>
              {rows[indexvalue].approverandowners.approver.map((user, i) => (
                <div key={user.username}>{user.displayname}</div>
              ))}
            </div>)
          })
      }
    } else if (rows[indexvalue].name === 'Apigee') {
      setTitle(<div style={{
        padding: 10,
        textAlign: 'center'
      }}
      >
        <b>APPROVERS</b>
        {rows[indexvalue].approverandowners.map((user, i) => (
          <div key={user}>{user}</div>
        ))}
      </div>)
    } else {
      setTitle(<div style={{
        padding: 10,
        textAlign: 'center'
      }}
      >
        <b>OWNER</b>
        <div style={{
          marginBottom: 10
        }}
        >
          {rows[indexvalue].approverandowners.owner.displayname}
        </div>
        <b>APPROVERS</b>
        {rows[indexvalue].approverandowners.approver.map((approverUser, i) => (
          <div key={approverUser.username}>{approverUser.displayname}</div>
        ))}
      </div>)
    }
  }

  /* to get displayname of username on mouseover */
  const getDisplayname = (value, id, head) => {
    const display = `${head.id}_displayname`
    // rows
    if (!id[display]) {
      setUsertooltip(<div>Loading...</div>)
      MyRequestService.getDisplayname(value).then((response) => {
        const [displayname] = response.data
        id[display] = displayname
        setUsertooltip(<div>{id[display].displayname}</div>)
      })
    } else {
      setUsertooltip(<div>{id[display].displayname}</div>)
    }
  }
  // Get Organization details on mouseover
  const getOrganization = (value, index1, head, position) => {
    const display = `${head.id}_organization`
    let index
    rows.map((value2, i) => {
      if (value2.id === position) {
        index = i
      }
    })
    if (!rows[index][display]) {
      setTitle(<div>Loading...</div>)
      MyRequestService.getUserHierarchy(value).then((response) => {
        rows[index][display] = response.data?.result
        setrows(rows)
        setTitle(<div>
          {rows[index][`${head.id}_organization`] ? (
            rows[index][`${head.id}_organization`].map((hierarchyUser, i) => (
              <div
                key={head.id}
                style={{
                  textAlign: 'center'
                }}
              >
                <b>{hierarchyUser.displayName}</b>
                <div>
                  <small>{hierarchyUser.title}</small>
                </div>
                {rows[index][`${head.id}_organization`].length - 1 !== i ? (
                  <ArrowDownwardIcon fontSize='small' />
                ) : null}
              </div>
            ))
          ) : (
            <div>loading...</div>
          )}
        </div>)
      })
    } else {
      setTitle(<div>
        {rows[index][`${head.id}_organization`] ? (
          rows[index][`${head.id}_organization`].map((organizationUser, i) => (
            <div
              key={head.id}
              style={{
                textAlign: 'center'
              }}
            >
              <b>{organizationUser.displayName}</b>
              <div>
                <small>{organizationUser.title}</small>
              </div>
              {rows[index][`${head.id}_organization`].length - 1 !== i ? (
                <ArrowDownwardIcon fontSize='small' />
              ) : null}
            </div>
          ))
        ) : (
          <div>loading...</div>
        )}
      </div>)
    }
  }
  // function to open alert modal box for tool access page
  const openModal = (key, toolname) => {
    const element = (
      <div>
        {' '}
        <ListItem key={`${key}1`}>
          {toolname === 'Jenkins - Artifactory' && (
            <a
              href={`${applink}${key.toLowerCase()}-ci-build`}
              target='_blank'
              rel='noreferrer'
            >
              <ListItemText primary={` (${key}) -ci-build`} />
            </a>
          )}
          {toolname === 'EAT Jenkins and Artifactory' && (
            <a
              href={`${applink}${key.toLowerCase()}-dev`}
              target='_blank'
              rel='noreferrer'
            >
              <ListItemText primary={` (${key}) -dev`} />
            </a>
          )}

        </ListItem>
        <ListItem key={`${key}2`}>
          {toolname === 'Jenkins - Artifactory' && (
            <a
              href={`${applink}${key.toLowerCase()}-cd-deploy`}
              target='_blank'
              rel='noreferrer'
            >
              <ListItemText primary={` (${key}) -cd-deploy`} />
            </a>
          )}
          {toolname === 'EAT Jenkins and Artifactory' && (
            <a
              href={`${applink}${key.toLowerCase()}-release`}
              target='_blank'
              rel='noreferrer'
            >
              <ListItemText primary={` (${key}) -release`} />
            </a>
          )}
        </ListItem>
        <ListItem key={`${key}3`}>
          <a
            href={`https://artifactrepo.jnj.com/artifactory/webapp/#
              /artifacts/browse/tree/General/${key.toLowerCase()}`}
            target='_blank'
            rel='noreferrer'
          >
            <ListItemText primary={` (${key}) -Artifactory`} />
          </a>
        </ListItem>
      </div>
    )
    setAlertboxMessage(element)
    setAlertopen(true)
  }
  // to be used in Jenkins-Artifactory tab of Tool Access page
  const getkeySection = (toolname, key) => {
    if (toolname === 'Jenkins - Artifactory' ||
    toolname === 'EAT Jenkins and Artifactory') {
      return (
        <Button
          onClick={() => openModal(key, toolname)}
          color='primary'
        >
          {key}
        </Button>
      )
    }
    if (applink) {
      return (
        <a target='_blank' href={getHref(applink, key)} rel='noreferrer'>
          {key}
        </a>
      )
    }
    return key
  }
  // function to return badge color for status
  const getBagdeColor = (value) => {
    switch (value) {
    case globalData.ACTION_APPROVED:
      return 'badge-pill badge-success'
    case globalData.ACTION_REJECTED:
      return 'badge-pill badge-danger'
    default:
      return 'badge-pill badge-warning'
    }
  }
  // function to get required icon with tooltip
  function getIcon (value, index, head, id) {
    switch (head.icon) {
    case 'available':
      return (
        <Tooltip
          enterDelay={500}
          enterNextDelay={500}
          leaveDelay={200}
          arrow
          title={(
            <Typography
              gutterBottom
            >
              Jenkins is
              {' '}
              {id.available}
            </Typography>
          )}
          TransitionComponent={Zoom}
        >
          {id.available === 'Unavailable' ? (
            <WarningIcon
              style={{
                transform: 'scale(1.3)'
              }}
              className={classes.warningIcon}
            />
          ) : (
            <CheckCircleIcon
              style={{
                transform: 'scale(1.3)'
              }}
              className={classes.aliveIcon}
            />
          )}
        </Tooltip>
      )
    case 'organization':
      return (
        <div>
          <Tooltip
            title={(
              <span style={{
                fontSize: 12,
                fontFamily: 'Verdana'
              }}
              >
                {usertooltip}
              </span>
            )}
            onMouseEnter={() => getDisplayname(value, id, head)}
          >
            <span style={{
              cursor: 'default'
            }}
            >
              {value}
            </span>

          </Tooltip>
          <LightTooltip
            TransitionComponent={Fade}
            onMouseEnter={() => getOrganization(value, index, head, id.id)}
            title={title}
            placement='right'
            arrow
          >
            <img
              src={organization}
              alt=''
              style={{
                marginLeft: 5
              }}
            />
          </LightTooltip>
        </div>
      )
    case 'approvers':
      return (
        <div>
          {value}
          {' '}
          <LightTooltip
            TransitionComponent={Fade}
            onMouseEnter={() => getApprovers(value, index, head, id.id)}
            title={title}
            placement='right'
            arrow
          >
            <AccountCircleIcon fontSize='small' />
          </LightTooltip>
        </div>
      )
    default:
      return null
    }
  }

  // to get the field values based on condition
  const getField = (type, value, id, head, index) => {
    switch (type) {
    case 'jenkinsbutton':
      return head.id === 'jenkinsdetailsbutton' ? (
        <Button
          variant='contained'
          style={{
            marginRight: '10px'
          }}
          color='primary'
          onClick={() => { jenkinsDetailsButton(id) }}
        >
          Details
        </Button>
      ) : (
        <p>test</p>
      )
    case 'button':
      return (
        <IconButton
          aria-label='delete'
          onClick={() => deleteItem(id.cart_id ? id.cart_id : id.id)}
          disabled={user.switch_user}
        >
          <DeleteIcon color='secondary' />
        </IconButton>
      )
    case 'global':
      return head.id === 'action_status' ? (
        <span className={getBagdeColor(value)}>
          {globalData.ACTION_NAME[value]}
        </span>
      ) : (
        globalData.ACTION_NAME[value]
      )
    case 'date':
      return id[head.id.split('_sort')[0]]
    case 'username':
      return (
        <Tooltip
          title={(
            <span style={{
              fontSize: 12,
              fontFamily: 'Verdana'
            }}
            >
              {usertooltip}
            </span>
          )}
          onMouseEnter={() => getDisplayname(value, id, head)}
        >
          <span style={{
            cursor: 'default'
          }}
          >
            {value}
          </span>
        </Tooltip>
      )
    case 'list':
      return (
        <div>
          {value.map((userValue, index) => (
            !id.moreButton && index < 5
              ? <li key={userValue}>{userValue}</li>
              : id.moreButton ? <li key={userValue}>{userValue}</li> : null

          ))}
          {value.length > 5 && (
            <Button
              color='primary'
              style={{
                padding: 0
              }}
              onClick={() => setMoreButton(id)}
            >
              {id.moreButton ? 'Less' : 'More'}
            </Button>
          )}
        </div>
      )
    default:
      return head.icon ? getIcon(value, index, head, id)
        : value
    }
  }
  const approveOrReject = (type) => {
    setButtonType(type)
    setESignbox(true)
  }
  const handleComplete = () => {
    setSelected([])
    setSelectedRequest([])
    setESignbox(false)
    handleCompleteRequest()
  }
  // function to handle search
  const handleSearch = (event) => {
    const data = rowsData
    let filteredDatas = []
    filteredDatas = data.filter((e) => {
      const mathesItems = Object.values(e)
      let retVal = null
      for (const e1 of mathesItems) {
        const regex = event.target.value.toString().toLowerCase()

        // if (typeof e1 === 'string') {
        retVal = e1 ? e1.toString().toLowerCase().match(regex) : null
        if (retVal !== null) {
          break
        }
        // }
      }

      return retVal
    })

    setrows(filteredDatas)
  }
  const isSelected = (name) => selected.indexOf(name) !== -1

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const changeApprover = (type, values) => {
    handleChangeApproverClick(type, values)
  }

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleSearch={handleSearch}
        selected={selected}
        selectedRequest={selectedRequest}
        approveOrReject={approveOrReject}
        showButton={showButton}
        handleChangeApproverClick={changeApprover}
        handleChangeOwner={handleChangeOwner}
      />
      <TableContainer>
        <Table
          aria-labelledby='tableTitle'
          size={dense ? 'small' : 'medium'}
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            checkbox={checkbox}
            showButton={showButton}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <>
                    <TableRow
                      onClick={
                        checkbox
                          ? (event) => handleClick(event, row.id, row)
                          : null
                      }
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={row.status ? classes[row.status] : null}

                    >
                      {checkbox ? (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                      ) : null}

                      {headCells.map((head) => (
                        <Tooltip
                          title={(
                            <span style={{
                              fontSize: 12,
                              fontFamily: 'Verdana'
                            }}
                            >
                              {row[head.id]}
                            </span>
                          )}
                          disableHoverListener={
                            head.id !== 'comment' ||
                              (row[head.id] && row[head.id].length < 20)
                          }
                        >
                          <TableCell
                            key={head.id}
                            component='th'
                            className={head.id === 'comment'
                              ? classes.descriptionCell
                              : (head.id === 'originalkey' &&
                                row.toolname !== 'Blazemeter') ||
                                head.type === 'button' || head.id === 'action'
                                ? classes.padding0
                                : classes.padding10}
                            align={
                              head.numeric ||
                                (head.id === 'originalkey' && !row.originalkey)
                                ? 'center'
                                : 'left'
                            }
                            id={labelId}
                            scope='row'

                            style={{
                              width: `${head.width}%`
                            }}
                          >
                            {head.id === 'originalkey' &&
                              row.toolname !== 'Blazemeter'
                              ? (
                                <IconButton
                                  color='primary'
                                  onClick={
                                    () => expandRow(index, head.page, row.id
                                      ? row.id
                                      : row.key, row.ticket_number
                                      ? row.ticket_number
                                      : null)
                                  }
                                  aria-label='Expand'
                                  component='span'
                                >
                                  {row.show
                                    ? <ExpandLessIcon />
                                    : <ExpandMoreIcon />}
                                </IconButton>
                              ) : null}

                            {head.id === 'originalkey' &&
                            head.page === 'Tool Access'
                              ? getkeySection(row.toolname, row[head.id])
                              : getField(head.type ? head.type : 'none',
                                row[head.id],
                                row,
                                head,
                                index)}
                          </TableCell>
                        </Tooltip>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          borderBottom: 0
                        }}
                        colSpan={3}
                      >

                        <Collapse
                          in={row.show}
                          timeout='auto'
                          unmountOnExit
                        >
                          {!row.expandedhtml && loadingExpand
                            ? 'loading...'
                            : row.expandedhtml}

                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                )
              })}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={checkbox ? headCells.length + 1 : headCells.length} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={isApproval ? [50, 100, 150, 200] : [5, 10, 25, 50]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <AlertDialog
        handleClose={alertClose}
        alertopen={alertopen}
        key='alert2'
        message={alertboxMessage}
        okbuttonalert
        title='Project Link'
      />
      <ESignComponent
        handleClose={alertClose}
        alertopen={eSignbox}
        title={buttonType}
        handleComplete={handleComplete}
        seletedRequests={selected}
        mailSelectedRequests={selectedRequest}
      />
    </div>
  )
}
EnhancedTable.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  rowsData: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  checkbox: PropTypes.bool,
  orderby: PropTypes.string.isRequired,
  applink: PropTypes.string,
  currentUser: PropTypes.string,
  handleCompleteRequest: PropTypes.func,
  showButton: PropTypes.string,
  numSelected: PropTypes.number,
  handleChangeApproverClick: PropTypes.func,
  jenkinsDetailsButton: PropTypes.func
}
