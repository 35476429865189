import React, {
  useContext
} from 'react'
import PropTypes from 'prop-types'
import {
  Typography, Tabs, Tab, Box, LinearProgress, Button, makeStyles
} from '@material-ui/core'
import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'
import SimpleTable from './delegateTable'
import DelegateServices from '../../services/api/delegateServices'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles({
  cellWarning: {
    textAlign: 'center',
    color: 'red'
  }
})

const timeZone = moment.tz.guess()

const headCells1 = [
  {
    id: 'start_date_use',
    numeric: false,
    label: 'Start Date',
    type: 'datatime'
  },
  {
    id: 'end_date_use',
    numeric: false,
    label: 'End Date',
    type: 'datatime'
  },
  {
    id: 'delegates',
    numeric: false,
    label: 'Delegated users',
    type: 'autocompleteusers'
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    type: 'text'
  },
  {
    id: 'edit',
    numeric: false,
    type: 'button',
    label: 'Edit',
    edit: true
  }
]

const headCells2 = [
  {
    id: 'start_date_use',
    numeric: false,
    label: 'Start Date',
    type: 'datatime'
  },
  {
    id: 'end_date_use',
    numeric: false,
    label: 'End Date',
    type: 'datatime'
  },
  {
    id: 'username',
    numeric: false,
    label: 'Delegated By',
    type: 'datatime'
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    type: 'text'
  }
]

const headCells3 = [
  {
    id: 'username',
    numeric: false,
    label: 'Username',
    type: 'autotext'
  },
  {
    id: 'start_date_use',
    numeric: false,
    label: 'Start Date',
    type: 'datatime'
  },
  {
    id: 'end_date_use',
    numeric: false,
    label: 'End Date',
    type: 'datatime'
  },
  {
    id: 'delegates',
    numeric: false,
    label: 'Delegated users',
    type: 'autocompleteusers'
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    type: 'text'
  },
  {
    id: 'edit',
    numeric: false,
    type: 'button',
    label: 'Edit',
    edit: true
  }
]

function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

export default function DelegateComponent () {
  const classes = useStyles()
  const {
    user
  } = useContext(UserContext)
  const [editIdx, setEditIdx] = React.useState(null)
  const [value, setValue] = React.useState(0)
  const [rows1, setRows1] = React.useState([])
  const [rows2, setRows2] = React.useState([])
  const [rows3, setRows3] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [row1Copy, setRow1Copy] = React.useState([])
  const [row3Copy, setRow3Copy] = React.useState([])
  const [directReports, setDirectReports] = React.useState([])

  React.useEffect(() => {
    getDelegateFunctions(user.name)
    getUserDirects1(user.name)
  }, [])

  // Frame structure for list of user as LI component
  function getUserlist (data) {
    return (
      <div>
        {data.map((userValue, index) => (
          <li key={userValue.username}>{userValue.username}</li>
        ))}
      </div>
    )
  }

  // SET DATE WHEN USER CHANGED IT
  const handleDateChange1 = (date, index, headingid) => {
    if (date != null) {
      let rowDetails = []
      if (value === 0) {
        rowDetails = rows1
      } else if (value === 2) {
        rowDetails = rows3
      }
      if (date.toString() === 'Invalid Date') {
        if (headingid === 'end_date_use') {
          rowDetails[index].end_date = date
        } else {
          rowDetails[index].start_date = date
        }
      } else if (headingid === 'end_date_use') {
        rowDetails[index].end_date = date.toISOString()
      } else {
        rowDetails[index].start_date = date.toISOString()
      }
      if (value === 0) {
        setRows1((prevrows1) => ([...prevrows1, ...rowDetails]))
      } else if (value === 2) {
        setRows3((prevrows3) => ([...prevrows3, ...rowDetails]))
      }
    }
  }

  // Adding name key for username to support autocomplete text box
  function getdelegates (data) {
    const result = data
    result.map((userValue, index) => (
      result[index].name = userValue.username
    ))
    return result
  }

  // Get table data for Delegations tab
  const getDelegateFunctions = (username) => {
    setLoading(true)
    DelegateServices.getDelegates(username, timeZone).then((response) => {
      if (response.data) {
        setLoading(false)
        const rows1data = response.data.other
        rows1data.map((data, index) => {
          const delegates1 = getUserlist(data.delegates)
          rows1data[index].delegatesdisplay = delegates1
          rows1data.delegates = getdelegates(data.delegates)
        })
        const rows2data = response.data.myreports
        rows2data.map((data, index) => {
          const delegates3 = getUserlist(data.delegates)
          rows2data[index].delegatesdisplay = delegates3
        })
        const rows3data = response.data.myreports
        rows3data.map((data, index) => {
          const delegates3 = getUserlist(data.delegates)
          rows3data[index].delegatesdisplay = delegates3
          rows3data.delegates = getdelegates(data.delegates)
        })
        setRows1(rows1data)
        setRows2(response.data.self)
        setRows3(rows3data)
        setRow3Copy(cloneDeep(rows3data))
        setRow1Copy(cloneDeep(rows1data))
      }
    })
  }

  // Get direct Reports users of the logged in user
  const getUserDirects1 = (username) => {
    DelegateServices.getUserDirects(username).then((response) => {
      setDirectReports(response.data)
    })
  }

  // Add username key in JSON when end user changes the username autocomplete text box
  const changeUser = (userValue, index) => {
    if (userValue && userValue.length > 0) {
      userValue.map((data, i) => {
        if (userValue[i].name) {
          userValue[i].username = data.name
        }
      })
      if (value === 0) {
        rows1[index].delegates = userValue
        setRows1((prevrows1) => ([...prevrows1, ...rows1]))
      } else if (value === 2) {
        rows3[index].delegates = userValue
        setRows1((prevrows3) => ([...prevrows3, ...rows3]))
      }
    }
  }

  const changeUser1 = (forUserName, i) => {
    const rowDetails = rows3
    if (forUserName) {
      rowDetails[i].username = forUserName
    }
    setRows3((prevrows3) => ([...prevrows3, ...rowDetails]))
  }

  // Set edit for Row
  const editDelegate = (i) => {
    setEditIdx(i)
  }

  // API call to save my delegates
  const saveDelegate = (i) => {
    setEditIdx(null)
    setLoading(true)
    let rowDetails = []
    if (value === 0) {
      rowDetails = rows1
    } else if (value === 2) {
      rowDetails = rows3
    }
    const inputJson = {
      id: rowDetails[i].id,
      start_date_use: rowDetails[i].start_date_use,
      end_date_use: rowDetails[i].end_date_use,
      comments: rowDetails[i].comments,
      delegates: rowDetails[i].delegates,
      timezone: 'UTC',
      delegated_by: user.name,
      username: rowDetails[i].username,
      start_date: rowDetails[i].start_date,
      end_date: rowDetails[i].end_date,
      status: rowDetails[i].status
    }
    const inputArray = []
    inputArray.push(inputJson)
    DelegateServices.updateDelegate(inputArray).then((response1) => {
      setRows1([])
      setRows3([])
      getDelegateFunctions(user.name)
    })
  }

  // Cancelling the delegate in Delegations page
  const cancelDelegate = (i) => {
    if (value === 0) {
      if (rows1[i].id) {
        rows1[i].delegates = row1Copy[i].delegates
        rows1[i].start_date = row1Copy[i].start_date
        rows1[i].end_date = row1Copy[i].end_date
      } else {
        rows1.splice(i, 1)
      }
      setRows1(rows1)
    } else {
      if (rows3[i].id) {
        rows3[i].delegates = row3Copy[i].delegates
        rows3[i].start_date = row3Copy[i].start_date
        rows3[i].end_date = row3Copy[i].end_date
        rows3[i].username = row3Copy[i].username
      } else {
        rows3.splice(i, 1)
      }
      setRows3(rows3)
    }
    setEditIdx(null)
  }

  // Set tab change values
  const handleChange = (event, newValue) => {
    const rows1CopyC = row1Copy.filter((item) => item.id)
    const rows3CopyC = row3Copy.filter((item) => item.id)
    setValue(newValue)
    setEditIdx(null)
    if (newValue === 2) {
      setRows3(rows3CopyC)
    } else if (newValue === 0) {
      setRows1(rows1CopyC)
    }
  }

  // Alert for Add new delegate
  const alertAddNew = () => {
    if (value === 0) {
      rows1.splice(0, 0, {
        end_date: new Date(),
        start_date: new Date(),
        delegates: [],
        start_date_use: '',
        end_date_use: ''
      })
      setRows1(rows1)
    } else if (value === 2) {
      rows3.splice(0, 0, {
        end_date: new Date(),
        start_date: new Date(),
        delegates: [],
        start_date_use: '',
        end_date_use: ''
      })
      setRows3(rows3)
    }
    setEditIdx(0)
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {editIdx === null && (value === 0 || value === 2) ? (
        <Button
          variant='contained'
          className='pull-right'
          disabled={loading}
          color='primary'
          aria-label='empty cart'
          onClick={() => alertAddNew()}
        >
          +Add New
        </Button>
      ) : null}

      <Tabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='on'
        indicatorColor='primary'
        textColor='primary'
        aria-label='scrollable force tabs example'
        style={{
          marginLeft: '-30px'
        }}
      >
        <Tab key='delegations' label='Delegations' />
        <Tab key='assigned' label='Assigned To Me' />
        <Tab key='supervisour' label='Supervisor Delegates' />
      </Tabs>

      <TabPanel index={0} value={value} key='tab-delegations'>
        {rows1.length && !loading ? (
          <SimpleTable
            tabValue={value}
            changeUser={changeUser}
            headCells={headCells1}
            rowsData={rows1}
            editDelegate={editDelegate}
            saveDelegate={saveDelegate}
            cancelDelegate={cancelDelegate}
            handleDateChange={handleDateChange1}
            directReports={directReports}
            editIdx={editIdx}
            key='delegationstable'
          />
        ) : null}
        {!loading && !rows1.length
          ? <div key='nodatatext1'>No data to display.</div>
          : null}

      </TabPanel>
      <TabPanel index={1} value={value} key='tab-assigned'>
        {rows2.length && !loading ? (
          <SimpleTable

            headCells={headCells2}
            rowsData={rows2}
            key='assignedtable'
          />
        ) : null}
        {!loading && !rows2.length
          ? <div key='nodatatext2'>No data to display.</div>
          : null}
      </TabPanel>
      <TabPanel index={2} value={value} key='tab-supervisour'>
        <div className={classes.cellWarning}>
          You can use the Supervisor Delegates section to create a
          delegate for a direct report
          who is not available to create their own delegate
        </div>

        {rows3.length && !loading ? (
          <SimpleTable
            tabValue={value}
            changeUser={changeUser}
            editDelegate={editDelegate}
            saveDelegate={saveDelegate}
            cancelDelegate={cancelDelegate}
            handleDateChange={handleDateChange1}
            directReports={directReports}
            editIdx={editIdx}
            headCells={headCells3}
            rowsData={rows3}
            changeUser1={changeUser1}
            key='supervisourtable'
          />
        ) : null}
        {!loading && !rows3.length
          ? <div key='nodatatext2'>No data to display.</div>
          : null}
      </TabPanel>
    </div>
  )
}
