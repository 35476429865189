import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

const {
  CancelToken
} = axios
let cancel

// Get Projects for ownership change
function getAllProjects (key) {
  if (cancel !== undefined) {
    cancel()
  }

  return axiosInstance.get(`api/v1/admin/getAllProjects?key=${key}`,
    {
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
}

// Get key roles for ownership change
function getkeyroles (key) {
  return axiosInstance.get(`api/v1/requestaccess/getkeyroles/${key}`)
}

// Get new ownername for ownership change
function getallusersforautocomplete (term) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`api/v1/requestaccess/getallusersforautocomplete
?term=${term}&type=owners`,
  {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

// Check if owner name exists for ownership change
function checkifusernameexists (username) {
  return axiosInstance.get(`api/v1/requestaccess/checkifusernameexists
?username=${username}`)
}

// Check if owner name exists for ownership change
function getGroupsbySearchkey (searchkey, isCustomGroup) {
  return axiosInstance.get(`api/v1/requestaccess/getGroupsbySearchkey
?searchkey=${searchkey}&isCustomGroup=${isCustomGroup}`)
}

// Change ownership for key
function setKeyOwnership (input) {
  return axiosInstance.post('/api/v1/admin/setKeyOwnership', input)
}

// Change ownership for geroup
function setGroupOwnership (input) {
  return axiosInstance.post('/api/v1/admin/setGroupOwnership', input)
}

function setCustomGroupOwnership (input) {
  return axiosInstance.post('/api/v1/admin/setGroupOwnership', input)
}

// archive users on archive projects
function archiveUsers (input) {
  return axiosInstance.post('/api/v1/archiverestore/archiveprojectusers', input)
}
// restore users on archive projects
function restoreUsers (input) {
  return axiosInstance.post('/api/v1/archiverestore/restoreprojectusers', input)
}
// get archived projects
function getArchivedProjects () {
  return axiosInstance.get('/api/v1/archiverestore/getArchivedProjects')
}
// get archived projects
function getArchivedGroups (key) {
  return axiosInstance.get(`/api/v1/archiverestore/getArchivedGroups?key=
${key}`)
}
// get archived projects
function getGroupsToArchive (key) {
  return axiosInstance.get(`/api/v1/archiverestore/getGroupsToArchive?key=
${key}`)
}

const OwnershipServices = {
  getAllProjects,
  getkeyroles,
  getallusersforautocomplete,
  checkifusernameexists,
  getGroupsbySearchkey,
  setKeyOwnership,
  setGroupOwnership,
  setCustomGroupOwnership,
  archiveUsers,
  restoreUsers,
  getArchivedProjects,
  getArchivedGroups,
  getGroupsToArchive
}

export default OwnershipServices
